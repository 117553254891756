import React from 'react';
import { Route, BrowserRouter, BrowserRouterProps } from 'react-router-dom';

import App from './components/pages/App';
import ComingSoon from './components/pages/ComingSoon';

const Routes = (props: BrowserRouterProps) => (
    <BrowserRouter {...props} >
        <Route path="/" component={ComingSoon} >
            <Route path="*" component={ComingSoon} />
        </Route>
    </BrowserRouter>
);
export default Routes;