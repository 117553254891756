import React, { useState, useEffect } from 'react';
import { CssBaseline, Container, Grid, Box, Typography } from '@material-ui/core';

import useStyles from './styles';
import "./styles.css";

const ComingSoon: React.FC = () => {
    const classes = useStyles();
    const finalDate = new Date("2019/10/27");
    const [msecDiff, setMsecDiff] = useState(finalDate.getTime() - new Date().getTime());
    const [seconds, setSeconds] = useState("");
    const [minutes, setMinutes] = useState("");
    const [hours, setHours] = useState("");
    const [days, setDays] = useState("");


    function reset() {
        setMsecDiff(finalDate.getTime() - new Date().getTime());
        setDays("");
        setHours("");
        setMinutes("");
        setSeconds("");
    }

    function parseTime(diff: number) {
        let msec = diff;
        let dd = Math.floor(msec / 1000 / 60 / 60 / 24);
        msec -= dd * 1000 * 60 * 60 * 24;
        setDays(dd + "");
        var hh = Math.floor(msec / 1000 / 60 / 60);
        msec -= hh * 1000 * 60 * 60;
        setHours(hh < 10 ? ("0" + hh + "") : (hh + ""));
        var mm = Math.floor(msec / 1000 / 60);
        msec -= mm * 1000 * 60;
        setMinutes(mm < 10 ? ("0" + mm + "") : (mm + ""));
        var ss = Math.floor(msec / 1000);
        msec -= ss * 1000;
        setSeconds(ss < 10 ? ("0" + ss + "") : (ss + ""));
    }

    useEffect(() => {
        let interval: any = null;
        interval = setInterval(() => {
            setMsecDiff(finalDate.getTime() - new Date().getTime());
            parseTime(msecDiff);
        }, 1000);
        if (msecDiff <= 0) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [true, msecDiff]);

    return (
        <React.Fragment>
            <CssBaseline>
                <Grid container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    className={classes.root}>
                    <div>
                        <Typography variant="h1" component="h2" gutterBottom>
                            WE ARE COMING SOON
                    </Typography>
                        <Typography variant="h4" component="h2" gutterBottom>
                            Our exciting new website is coming soon! Check back later...
                    </Typography>
                    </div>
                    <Grid container spacing={2} className={classes.countdownContainer} direction="row">
                        <Grid item xs className={classes.countdownItem}>
                            <Box className={classes.paper} flexDirection="column">
                                <Typography variant="h1" component="h2" >
                                    {days}
                                </Typography>
                                <Typography variant="h4" component="h2" >
                                    days
                            </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs className={classes.countdownItem}>
                            <Box className={classes.paper} flexDirection="column">
                                <Typography variant="h1" component="h2" >
                                    {hours}
                                </Typography>
                                <Typography variant="h4" component="h2" >
                                    hours
                            </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs className={classes.countdownItem}>
                            <Box className={classes.paper} flexDirection="column">
                                <Typography variant="h1" component="h2" >
                                    {minutes}
                                </Typography>
                                <Typography variant="h4" component="h2" >
                                    minutes
                            </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs className={classes.countdownItem}>
                            <Box className={classes.paper} flexDirection="column">
                                <Typography variant="h1" component="h2" >
                                    {seconds}
                                </Typography>
                                <Typography variant="h4" component="h2" >
                                    seconds
                            </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </CssBaseline>
        </React.Fragment>
    );
}

export default ComingSoon;