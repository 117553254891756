import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        textAlign: 'center',
        color: "#fff",
        width: '100%',
        minHeight: "100vh",
        display: "flex",
        background: "#aaa"
    },
    countdownContainer: {
        maxWidth: "80%",
        margin: "0 auto"
    },
    countdownItem: {
        display: "flex",
        justifyContent: "center"
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.primary.contrastText,
        background: "#4A90E2",
        height: 200,
        width: 200,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 10
    }
}));
export default useStyles;